import Prices from "components/Prices";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { MyCartQueryResponse } from "graphql/queries/types";
import { addOrderAddressMutation } from "graphql/mutations/add-address-to-order.mutation";
import { setOrderShippingMethodMutation } from "graphql/mutations/set-shipping-method.mutation";
import { addPaymentToOrderMutation } from "graphql/mutations/add-payment-to-order.mutation";
import { changeOrderStateMutation } from "graphql/mutations/change-order-state.mutation";
import { AddOrderAddressInput } from "type";
import { pdf } from "@react-pdf/renderer";
import { CheckOutPdf } from "components/CatelogPdf/Pdf";
import { setKitNameMutation } from "graphql/mutations/update-order-kitName.mutaion";
import {
  SellerDetails,
  eligibleShippingMethodsQuery,
  myCartQuery,
  orderStateQuery,
  paymentMethodsQuery,
} from "graphql/queries/queries";
import Badge from "shared/Badge/Badge";
import formatCurrencyINR from "utils/convertNumbToCurrency";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ShippingAddress from "./ShippingAddress";
import useOrderStore from "hooks/useCart";
import NcImage from "shared/NcImage/NcImage";
import useCurrentUser from "hooks/useCurrentUser";
import toast from "react-hot-toast";

const DemoImageURL = "/assets/placeholder-small.png";

const isImageURLValid = async (url: any) => {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch (error) {
    return false;
  }
};

const CheckoutPage = () => {
  const navigator = useNavigate();
  const [downloadWithoutPrice, setDownloadWithoutPrice] = useState(false);
  const getUser = useCurrentUser((state) => {
    return {
      user: state.user,
      getUser: state.getUser,
      isLoggedIn: state.isUserLoggedIn,
    };
  });

  const resetOrderStore = useOrderStore((state) => state.resetOrder);
  const orderStore = useOrderStore((state) => state.order);

  if (!orderStore || !orderStore.lines || orderStore?.lines?.length === 0) {
    if (getUser.isLoggedIn) navigator("/account-my-order");
    else navigator("/");
  }
  const FetchSeller = useQuery(SellerDetails, { fetchPolicy: "no-cache" });
  const { data, loading, error, refetch } =
    useQuery<MyCartQueryResponse>(myCartQuery);
  const [seller, setSeller] = useState<{
    mobile: string;
    website: string;
  } | null>(null);
  const shippingMethods = useQuery(eligibleShippingMethodsQuery);
  const paymentMethods = useQuery(paymentMethodsQuery);

  const [orderAddress, setOrderAddress] = useState<AddOrderAddressInput>({
    fullName: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    province: "",
    postalCode: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
  });
  const orderState = useQuery(orderStateQuery);
  const [addOrderAddress, addOrderAddressProps] = useMutation(
    addOrderAddressMutation
  );
  const [setOrderShippingMethod, setOrderShippingMethodProps] = useMutation(
    setOrderShippingMethodMutation
  );
  const [addPaymentToOrder, addPaymentToOrderProps] = useMutation(
    addPaymentToOrderMutation
  );
  const [changeOrderState, changeOrderStateProps] = useMutation(
    changeOrderStateMutation
  );
  const [setOrderKitName, setOrderKitNameProps] =
    useMutation(setKitNameMutation);

  const handleChangeOrderState = async () => {
    await changeOrderState({
      variables: {
        state: "ArrangingPayment",
      },
    });
  };

  const handleAddPaymentToOrder = async () => {
    await addPaymentToOrder({
      variables: {
        method: "standard-payment",
        metaData: JSON.stringify({ transactionID: "test" }),
      },
    });
  };

  const handleAddOrderAddress = async (data: AddOrderAddressInput) => {
    await addOrderAddress({
      variables: {
        ...data,
      },
    });
  };

  const handleSetOrderShippingMethod = async () => {
    await setOrderShippingMethod({
      variables: {
        id: "1",
      },
    });
  };

  const downloadCatalog = async (kitname: string) => {
    if (!kitname || kitname === "") kitname = "kit";
    const actualKitName = kitname;
    kitname = kitname.replace(/[^a-zA-Z0-9 ]/g, "");
    kitname = kitname.replace(/\s+/g, "-").toLowerCase();

    if (!processedData) return;
    const pdfBloc = await pdf(
      <CheckOutPdf
        data={processedData}
        seller={seller}
        kitname={actualKitName}
        downloadWithoutPrice={downloadWithoutPrice}
      />
    ).toBlob();
    const url = URL.createObjectURL(pdfBloc);
    const link = document.createElement("a");
    link.href = url;
    link.download = kitname + ".pdf";
    document.body.appendChild(link);
    link.click();
  };

  const placeOrder = async () => {
    toast.success(
      "We are processing your order and generating catalog please wait"
    );

    // get kit name from local storage
    const kitName = localStorage.getItem("kitName") ?? "";

    // set kit name to order
    await setOrderKitName({
      variables: {
        kitName: kitName,
      },
    });

    await handleChangeOrderState();

    await handleAddPaymentToOrder();

    toast.success("Order Placed Successfully");
    await downloadCatalog(kitName); // download pdf
    resetOrderStore();
    localStorage.removeItem("kitName");
    if (getUser.isLoggedIn) {
      navigator("/");
    } else {
      navigator("/");
    }
  };

  const [processedData, setProcessedData] = useState<any>(null);

  useEffect(() => {
    if (!loading && data && data.activeOrder) {
      const processedLines = data?.activeOrder?.lines?.map(async (line) => {
        const imageUrl = line.productVariant.product.featuredAsset?.preview;

        // check if product variant have more than 1 assets then use else check in product assets if it has more than 1 then use else set empty array
        const variantAssetLength = line.productVariant.assets.length;
        const productAssetLength = line.productVariant.product.assets.length;
        let assets =
          variantAssetLength > 1
            ? line.productVariant.assets
            : productAssetLength > 1
            ? line.productVariant.product.assets
            : [];

        // now check if any of the image matches with imageUrl then remove it from assets array
        assets = assets.filter((asset) => asset.preview !== imageUrl);

        let procesingData = line;

        if (!assets.length) {
          procesingData = { ...procesingData, assets: [] };
        } else {
          assets.forEach((asset, i) => {
            const isValid = isImageURLValid(asset.preview);
            if (!isValid) {
              assets[i].preview = DemoImageURL;
              procesingData = { ...procesingData, assets: assets };
            }
          });
          procesingData = { ...procesingData, assets: assets };
        }

        if (!imageUrl) {
          procesingData = { ...procesingData, imageUrl: DemoImageURL };
        } else {
          const isValid = await isImageURLValid(imageUrl);

          if (!isValid) {
            procesingData = { ...procesingData, imageUrl: DemoImageURL };
          }
          procesingData = { ...procesingData, imageUrl };
        }
        return procesingData;
      });

      // Use Promise.all to await the completion of all asynchronous operations
      Promise.all(processedLines).then((processedLinesData) => {
        const processedOrder = {
          ...data.activeOrder,
          lines: processedLinesData,
        };
        setProcessedData({ activeOrder: processedOrder });
      });
    }
  }, [loading, data]);

  useEffect(() => {
    if (
      !FetchSeller.loading &&
      FetchSeller.data &&
      FetchSeller.data.activeChannel &&
      FetchSeller.data.activeChannel.seller
    ) {
      const mobile =
        FetchSeller.data?.activeChannel?.seller?.customFields?.mobile;
      const website =
        FetchSeller.data?.activeChannel?.seller?.customFields?.website;
      setSeller({ mobile: mobile, website: website });
    }
  }, [FetchSeller.data, FetchSeller.loading]);

  useEffect(() => {
    refetch();
  });

  useEffect(() => {
    const address = {
      fullName:
        orderAddress.fullName ||
        data?.activeOrder?.shippingAddress?.fullName ||
        "",
      streetLine1:
        orderAddress.streetLine1 ||
        data?.activeOrder?.shippingAddress?.streetLine1 ||
        "",
      streetLine2:
        orderAddress.streetLine2 ||
        data?.activeOrder?.shippingAddress?.streetLine2 ||
        "",
      city: orderAddress.city || data?.activeOrder?.shippingAddress?.city || "",
      province:
        orderAddress.province ||
        data?.activeOrder?.shippingAddress?.province ||
        "",
      postalCode:
        orderAddress.postalCode ||
        data?.activeOrder?.shippingAddress?.postalCode ||
        "",
      countryCode: data?.activeOrder?.shippingAddress?.country || "",
      phoneNumber:
        orderAddress.phoneNumber ||
        data?.activeOrder?.shippingAddress?.phoneNumber ||
        "",
      email: data?.activeOrder?.shippingAddress?.customFields?.email || "",
    };

    setOrderAddress(address);
  }, [orderState.data, data, shippingMethods.data, paymentMethods.data]);

  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  if (
    loading ||
    addOrderAddressProps.loading ||
    setOrderShippingMethodProps.loading ||
    addPaymentToOrderProps.loading ||
    changeOrderStateProps.loading ||
    FetchSeller.loading ||
    setOrderKitNameProps.loading
  )
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );

  if (
    error ||
    addOrderAddressProps.error ||
    setOrderShippingMethodProps.error ||
    addPaymentToOrderProps.error ||
    changeOrderStateProps.error ||
    FetchSeller.error ||
    setOrderKitNameProps.error
  )
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">
          Something went wrong!
        </h1>
        <ButtonPrimary
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </ButtonPrimary>
      </div>
    );

  const renderProduct = (item: any, index: number) => {
    const id = item?.id;
    const productId = item?.productVariant?.id;
    const slug = item?.productVariant?.product?.slug;
    const image = item?.productVariant?.product?.featuredAsset?.preview;
    const price = item?.linePriceWithTax;
    const linePrice = item?.linePrice;
    const linePriceWithTax = item?.linePriceWithTax;
    const name = item?.productVariant.name;
    const quantity = item?.quantity;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <NcImage
            src={image}
            alt={name}
            containerClassName="h-full w-full object-cover object-center"
            className="h-full w-full object-cover object-center"
          />
          <Link to={"/product/" + slug} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold font-inter">
                  <Link to={"/product/" + slug}>{name}</Link>
                </h3>

                <div className="mt-3 flex justify-between w-full sm:hidden relative font-inter">
                  <Badge name={quantity} />
                  {/* <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  /> */}
                  <h1 className="text-base text-black font-semibold font-inter">
                    {formatCurrencyINR(price)}
                  </h1>
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                {/* <Prices price={price} className="mt-0.5" /> */}
                <h1 className="text-base text-black font-semibold font-inter">
                  {formatCurrencyINR(price)}
                </h1>
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <Badge name={quantity} className="relative z-10 p-9 font-inter" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="space-y-8 ">
        <div id="ContactInfo" className="scroll-mt-24"></div>

        <div id="ShippingAddress" className="scroll-mt-24">
          <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            orderAddress={orderAddress}
            setOrderAddress={setOrderAddress}
            handleAddOrderAddress={handleAddOrderAddress}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Checkout || 360 Customzier</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold font-inter">
            Checkout
          </h2>
        </div>

        <div className="flex flex-col lg:flex-row ">
          <div className="flex-1 ">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold font-inter">Order summary</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 max-h-screen hiddenScrollbar overflow-auto font-inter">
              {data?.activeOrder?.lines?.map(renderProduct)}
            </div>
            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              <div
                onClick={handleAddPaymentToOrder}
                className="flex justify-between py-2.5 font-inter"
              >
                <span>Tax estimate</span>
                <span className="font-semibold font-inter text-slate-900 dark:text-slate-200">
                  {data?.activeOrder?.totalWithTax &&
                    data?.activeOrder?.total &&
                    formatCurrencyINR(
                      data?.activeOrder?.totalWithTax - data?.activeOrder?.total
                    )}
                </span>
              </div>

              <div
                onClick={handleSetOrderShippingMethod}
                className="flex justify-between font-semibold font-inter text-slate-900 dark:text-slate-200 text-base pt-4"
              >
                <span>Order total</span>
                <span>
                  {formatCurrencyINR(data?.activeOrder?.totalWithTax || 0)}
                </span>
              </div>
            </div>
            {data?.activeOrder?.shippingAddress?.fullName &&
              data?.activeOrder?.shippingAddress?.phoneNumber && (
                <div className="flex flex-col items-center justify-center mt-4">
                  <label className="flex items-center text-gray-700 mb-4">
                    <input
                      type="checkbox"
                      checked={downloadWithoutPrice}
                      onChange={() =>
                        setDownloadWithoutPrice(!downloadWithoutPrice)
                      }
                      className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2">Without Price</span>
                  </label>
                  <ButtonPrimary
                    loading={loading}
                    onClick={() => {
                      placeOrder();
                    }}
                    className="w-full hover:bg-[#0068FF] rounded-md font-inter"
                  >
                    Download Catalogue
                  </ButtonPrimary>
                </div>
              )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;
